import React, { Component } from 'react';
import '../../Content/css/404.css'
import People from '../../Content/images/404/People.png'
import PageHeader from '../PageComponents/PageHeader';
import {Link} from 'gatsby';
export default class FourOFour extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	  	style: {height: '100%',},
	  	wrapperStyle: {},
	  };
	}
	componentDidMount(){
		this.setState({
			style: {
				height: window.innerHeight,
			},
			wrapperStyle: {
				backgroundImage: 'url(' + People + ')',
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
			}
		})
	}
	render() {	
		return (
			<div className="h-screen flex items-center w-full">
				<div className="-mt-40 w-full relative">
					<div className="pageGrid" style={this.state.style}>
						<div className="page404 text-center orangeText">
							<div>
								<PageHeader mainHeading="Error: 404" subHeading="Page Not Found"/>
								<img src={People} alt=""/>
								<Link to="/" className="bg-primary text-white py-2 px-6 rounded-lg mt-6 md:mt-2">Go to the home page</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
