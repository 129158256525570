import React, { Component } from 'react'
import Page404 from '../components/404/';
export default class Error404 extends Component {
    render() {
        return (
            <main>
                <Page404/>
            </main>
        )
    }
}
